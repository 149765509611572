@import 'var';
@import 'mixin';

@font-face {
  font-family: 'Helvetica Neue'; 
  src: url('../src/Assets/Fonts/Helvetica.ttf') format('truetype'); 
}

@font-face {
  font-family: 'Helvetica Neue Bold'; 
  src: url('../src/Assets/Fonts/Helvetica-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro'; 
  src: url('../src/Assets/Fonts/SourceSansPro-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Allura'; 
  src: url('../src/Assets/Fonts/Allura-Regular.ttf') format('truetype');
}

*:not(.glyphicon, .sign){
  font-family: 'Helvetica Neue', Arial, sans-serif !important;
  scrollbar-width: thin;
  scrollbar-color: $grey;
  &:hover{
    scrollbar-color: $dark-grey;
  }
}

.ant-tabs-tab {
  &.ant-tabs-tab-active {
    border-top: 2px solid $primary !important;
    border-left: 2px solid $primary !important;
    border-right: 2px solid $primary !important;

    .ant-tabs-tab-btn {
      color: $darker-grey !important;
    }
  }

  border-bottom: none !important;
}

.ant-tabs-top >.ant-tabs-nav::before{
  border-color: $primary !important;
}

.sign{
  font-family: 'Allura', 'Helvetica Neue', Arial, sans-serif;
}

.ant-card-head-title:not(.glyphicon), 
.highcharts-title:not(.glyphicon){
  font-family: 'Helvetica Neue Bold', 'Helvetica Neue', Arial, sans-serif !important;
  overflow: visible !important;
  text-overflow: unset !important;
}

body {
  background:$body-color !important;
  color: $lighter-grey !important;
}

.ant-layout {
  background: $light-grey !important;
}

/* Scrollbar Track */
::-webkit-scrollbar-track {
  background: $light-grey; 
}
 
/* Scrollbar Handle */
::-webkit-scrollbar-thumb {
  background: $grey; 
  &:hover {
    background: $dark-grey;
  }
}

.ant-table-row {
  &.ant-table-row-selected{
    .ant-table-cell-row-hover {
      background-color: #e6f4ff !important;
    }
  }
  .ant-table-cell-row-hover {
    background-color: unset !important;
  }
}

.green-text {
  color: $green !important;
}
.btnMargin{
  margin-top: 30px;
}
.studentTable {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important
}
.btnMarginBtm{
  margin-bottom:20px ;
  margin-left: 15px;
}

.text_danger,.text_danger input {
  color: red !important;
}

.text_dark,.text_dark input {
  color: black !important;
}

.grey-card{
  background-color: #f0f0f0;
}
.cursor-pointer{
  cursor: pointer;
}
.partTimeCheck .ant-form-item-row{
  flex-flow: unset !important;
}
.partTimeCheck .ant-form-item-row .ant-form-item-label{
 overflow: unset !important;
 white-space: nowrap !important;
 margin-top: 5px ;
}
.box{
  width: 20px;
  height: 20px;
  border: 1px solid;
  &.legend-color-1{
    background-color: $white;
  }
  &.legend-color-2{
    background-color: #F7EED1;
  }
  &.legend-color-3{
    background-color: #C8F3E0;
  }
  &.legend-color-4{
    background-color: #EBC2BD;
  }
}
.text-primary-pale{
  color: $primary-pale !important;
}
.nested-table{
  thead{
  tr:nth-child(2){
    display: none;
  }
}
}
.backButton{
  color:#3379b7 !important;
  border-color: #3379b7 !important;
}
.no-exclamation {
    &.form-control:invalid
    {background-image: none !important;}
}

.mentorTable .ant-pagination{
  margin:16px 0px 0px 0px !important;
}

.mentorTable{
  margin-bottom: unset !important;
  padding-bottom: unset !important;
}

.hiddenSurveysChkBx .ant-checkbox-inner{
  height: 20px;
  width:20px;
}
.newPadding{
  padding: 0px 0px 0px 2px !important;
}
.addNotificationEditor .tox-tinymce .tox-statusbar .tox-statusbar__path{
  display:none;
}
.recepientsTab ul li.ant-pagination-prev,.recepientsTab ul li.ant-pagination-item{
  margin-inline-end:0px !important;
}
.recepientsTab ul li.ant-pagination-item{
  height:24px;
  min-width:24px;
  line-height: unset;
  position: relative;
  top: 3px;
}
.optionCheckbox{
  display:flex;
  align-items: center;
}
.newHeight{
  height:60px;
}
.newPosition{
  position:relative;
  top:-52px;
}
.academicSpecInput .ant-input-number-input-wrap input{
  text-align:right;
  position:relative;
  right:16px;
}
.newHeight .ant-input-number{
  width:150px !important;
}
.selectCompWidth{
  // width:180px;
  width:130px;
  .ant-select-selector{
    border: 1px solid #b7b7b7 !important;
  }
}
.selectCompWidth150{
  width: 150px;
  .ant-select-selector{
    border: 1px solid #b7b7b7 !important;
  }
}
.bgGreen500{
   background-color: $lime-green;
   color:$white;
}
.bgGreen500:hover{
color:$white !important;
background-color: $darker-lime-green !important;
border-color:$darker-lime-green !important;
}
.div-style{
  margin-bottom: 5px;
  background-color: aliceblue;
  padding: 10px;
  border-radius: 5px;
}
.info-margin{
  margin-block: auto;
}

.fs-7 {
  font-size: 0.875rem;
}
.btn:disabled{
  cursor: not-allowed !important;
  pointer-events: auto !important;
}
.form-textarea{
  overflow-y: visible;
  resize: vertical;
}
.newTab >.ant-tabs-nav .ant-tabs-tab-active{
   border:2px solid $primary-pale;
}
.newTab >.ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn{ 
  color:#555555;
  font-weight: 500;
}
.newTab >.ant-tabs-nav .ant-tabs-tab{
  color:$primary-pale;
}

.ant-btn-primary:not(.ant-btn-dangerous ){
  @include CommonBG($primary, $primary);

  &:hover{
    @include CommonBG($primary-dark, $primary-dark);
  }

  &:disabled{
    @include CommonBG($disabled, $disabled);
    color: $white;
  }
}
.btn-primary {
  @include CommonBG($primary, $primary);

  &:hover {
    @include CommonBG($success, $success);
  }
}

.alert-message {
  color: $white !important;
  padding: 0.5rem !important;

  &.alert-danger {
    background-color: $danger;
  }

  &.alert-success {
    background: $success;
  }
}
.no-padding{
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.surveyResultCard .ant-card-body{
  padding: unset !important;
}
.surveyResultCard .ant-card-head{
  background:#337ab7;
  color: #fff;
  border-radius: 0.25rem;
}
.totalRow{
  background-color: #e8e8e8;
}
.mentorCard .ant-card-body .ant-collapse{
  border:unset !important;
}
.academicSpecTab table tbody tr td:nth-child(2),.academicSpecTab table tbody tr td:nth-child(3){
  padding:unset !important;
}
#specValues .ant-table-container .ant-table-content table tbody>tr td:nth-child(1){
  vertical-align: top !important;
}
.shwAllNotisChkbox .ant-checkbox .ant-checkbox-inner{
  width:18px !important;
  height:18px !important;
}
.forgot-link {
  color: $primary;

  &:hover {
    color: $success;
    cursor: pointer;
  }
}
.ant-picker-dropdown,.ant-select-dropdown{
   z-index: 99999999999999999999 !important;
}

.login-card {
  *:not(.glyphicon){
    font-family: 'Source Sans Pro', 'Helvetica Neue', Arial, sans-serif !important;
  }
  width: 450px;
  min-height: 400px;

  .card-img-top {
    background-image: url('./Assets/login.jpg');
    background-size: cover;
    padding: 1rem 2.5rem;
  }

  .was-validated .form-control:valid {
    @include CommonBG(none, $pale-blue );

    &:focus {
      @include CommonBG(none, $pale-blue );
    }
  }

  .form-control.is-valid {
    @include CommonBG(none, $pale-blue );

    &:focus {
      @include CommonBG(none, $pale-blue );
    }
  }
}

.student-staff-tabs.nav-underline {
  margin-bottom: 0.5rem !important;
  margin-top: 1rem !important;
  --bs-nav-underline-border-width: 0.2rem !important;

  .nav-item {
    width: 48%;
    display: grid;

    .nav-link {
      &.active {
        border-bottom-color: $primary !important;

        &:hover,
        &:focus {
          border-bottom-color: $primary !important;
        }
      }

      &:hover,
      &:focus {
        border-bottom-color: transparent !important;
        font-weight: 700;
      }

      &:not(.nav-link.active) {
        color: $grey !important;
      }
    }
  }
}

.card-tabs.nav-tabs {
  border-color: $primary-pale;
  gap: 0.2rem;

  .nav-link {
    background-color: #fafafa;
    border: 1px solid #f0f0f0;
    &:not(.active) {
      color: $primary;
    }

    &:hover,
    &:focus {
      background-color: $light-grey;
      color: $dark-grey !important;
    }

    &.active {
      border-width: 2px;
      border-color: $primary-pale $primary-pale $white $primary-pale;
      font-weight: 600;

      &:hover,
      &:focus {
        background-color: $white;
      }
    }
  }
}

.btn-info {
  @include CommonBG($btn-color, $btn-color);
  color: $white !important;

  &:hover {
    @include CommonBG($primary-dark, $primary-dark);
  }
  &:disabled{
    cursor: not-allowed;
    @include CommonBG($disabled , $disabled);
  }
}

.container-border-custom {
  border-top: 3px solid $primary-pale;

  &:not(.container-border-custom.dashboard-card){
    min-height: calc(100vh - 130px);
  }

  &.ant-card:not(.register-card) {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);

    .ant-card-head {
      padding: 10px;
      font-size: 18px;
      min-height: 48px;
    }

    .ant-card-body {
      padding: 12px;
    }
  }
}
.container-border-custom-top{
  border-top: 3px solid $primary-pale;
}
.custom-expanded-row {
  background-color: #f0f0f0;
}
.drag-custom {
  box-shadow: 4px 4px 0px 0px rgba(0, 0, 0, 0.1);
}

.records-table {

  .ant-pagination-total-text {
    flex-grow: 1;
    margin-inline-end: 0;
    width: fit-content;
  }

  .ant-table {

    border-collapse: collapse;

    thead tr {
      th {
        @include RecordTable;
        transition: none !important;
        border-inline-end: 1px solid $white-gray !important;
        border-collapse: collapse !important;
        // text-align: left !important;
        &::before {
          display: none;
        }
        &.ant-table-selection-column{
          text-align: center !important;
        }
      }

      td {
        @include RecordTable;
      }
    }

    tbody {
      td {
      border: 1px solid $white-gray !important;
      border-collapse: collapse !important;
      vertical-align: top !important;
    }
    tr{
      cursor: pointer;
    }
  }

  }

  &.ant-table-wrapper table {
    border-collapse: collapse !important;
  }
}

.main-header.ant-layout-header {
  background-color: $primary-pale;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0;
  padding-right: 0;
  .menu-btn{
    min-height: 50px;
    min-width: 50px;
    border-radius: 0;
  }
}

.logo-area {
  background-color: $primary-hover;
  height: 50px;
}

.ant-layout-sider {
  background: $pale-grey !important;

  .ant-menu-dark {
    background: $pale-grey;

    .ant-menu-item {
      @include Sidemenu(transparent);
      padding-left: 15px !important;
      a {
        text-decoration: none;
      }

      &.ant-menu-item-selected {
        background: $darker-grey;
        @include Sidemenu($primary-pale);
      }

      &:hover {
        background-color: $darker-grey !important;
        @include Sidemenu($primary-pale);
      }
    }

    .ant-menu-submenu {
      .ant-menu-submenu{
        .ant-menu-submenu-title{
          border-left: none !important;
          &::before{
            content: '◯';
            margin-right: 10px;
            font-weight: bold;
            font-size: 0.75rem;
            line-height: 1rem;
          }
        }
      }
      .ant-menu-submenu-title{padding-left: 15px !important;}
      &.ant-menu-submenu-open {

        .ant-menu-submenu-title {
          background: $darker-grey;
          @include Sidemenu($primary-pale);
        }
        ul{
          li:not(.ant-menu-submenu-inline){
            height: 35px;
            border-left: none;
            padding-left: 25px !important;
            background-color: #000c17;
            font-size: small;
            &::before{
              content: '◯';
              margin-right: 10px;
              font-weight: bold;
              font-size: 0.75rem;
              line-height: 1rem;
            }
          }
          .ant-menu-submenu-inline{
            .ant-menu-submenu-title{
              padding-left: 24px !important;
            }
          }
        }
      }
    }
  }
}

.records-popup .modal-dialog {
  max-width: 600px !important;

  .modal-content {
    border-radius: 0 !important;

    .modal-header {
      background-color: $primary-pale;
      color: $white;
      border-radius: 0 !important;

      .modal-title {
        font-size: 1.125rem !important;
      }
    }

    .modal-body {
      .ant-table-footer {
        border: 0 !important;
        background: none;
        padding-left: 0 !important;
      }
    }
  }
}

.ant-pagination-disabled {
  button {
    cursor: not-allowed !important;
    color: $grey !important;
  }
}

.btn-transparent {
  @include CommonBG($invisible, $invisible);

  &:hover {
    @include CommonBG($invisible, $invisible);
  }
}

.current-page-link {
  background: $invisible;

  a,
  li {
    text-decoration: none;
    color: $darker-grey
  }

  ol {
    margin-block-end: 0;
  }
}

.upload-container {
  .ant-upload.ant-upload-drag {
    width: 50%;
    background-color: $light-blue;
    height: auto;
    max-height: 500px;
  }

  .ant-upload-list.ant-upload-list-picture {
    width: 48%;
  }
}

h4 {
  margin-block-end: 0;
}
.highlight {
  background-color: $dark-cyan;
  color: $white;
}
.ant-layout-sider-collapsed{
  max-width: 50px !important;
  min-width: 50px !important;
  width: 50px !important;
  .ant-menu-inline-collapsed {
  
    .ant-menu-item
      {
      padding-left: 7px !important;
      }
  
    .ant-menu-submenu {
      .ant-menu-submenu-title {
        padding-left: 7px !important;
      }
    }
  }
}

.warning{
  color:$yellow;
}
.register-card {
  margin-bottom: 5rem;
  
 Form .form_data{
    margin: 0;
  }
  .faculty-inp{
    width: 80%;
  }
  .faculty-inp-num{
    width: 80%;
  }
  .faculty-inp-special-numbers{
    width: 90%;
  }
}

.faculty-icon-container{
  background-color: $white;
}

.photoPick {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 50%;
  padding: 0.25rem;
  background: $light-grey;
}

.signaturePic{
  display: block;
  align-self: center;
  border-radius: 0;
  padding: 10px;
  background: $light-grey;
}

.form_data{
  margin: 0;
}

.badgeWidth{
  width:35px;
}
.upload-img{
  position: absolute;
  align-self: center;
  margin-left: 4rem;
  margin-top: 2rem;
  width: 10px;
  .upload-image-btn{
   height: 0;
   width: 0;
  }
  .edit-icon{
    width: 30px;
    height: 20px;
    color: $dark-cyan;
    cursor: pointer;
  }
}

.link-text{
  text-decoration: none;
  color:$white;
}

.link-text:hover{
  color: $white;
}

.search-inp{
  width: 80%;
  
}

.mail-form{
  .mail-input{
    margin: 10px;
   }
}

.accordion .email-content.accordion-item {
  .accordion-header {
    .accordion-button {
      background-color:  $primary-pale !important;
      color: $white !important;
    }
  }
}
.accordion-button::after{
  filter: brightness(0) invert(1);
}


.email-content-header{
  background-color: $primary-dark;
}

.modal-content .modal-dialog .modal-footer{
  border-top: none !important;
}

.multi-select-btn{
  width: 160px;
  position: relative;
  .ant-select-selector{
    background: $btn-color !important;
    padding: 0 10px !important;
    border: 1px solid #b7b7b7 !important;
    &::before{
      content: 'Show / Hide Columns';
      background:$btn-color;
      position: absolute;
      color: $white;
      z-index: 2;
      cursor: pointer;
      border: 1px solid $btn-color;
    }
  }
  .ant-select-arrow{
    display: none;
  }
}
.action-button-container{
  justify-content: flex-end;
  padding-bottom: 10px;
}
.action-btn {
  white-space: nowrap; /* Prevent text wrapping */
  padding: 0 8px; /* Adjust padding if needed */
  max-width: 100%; /* Ensure button does not exceed column width */
}
.form-Class{
  padding: 2px;
}
.error_message{
  background-color: $danger;
  color: $white;
  display: flex;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
}
.adjustMargin{
  margin-top: 10px;
  margin-bottom: -10px;
}
.spanMargin{
  margin-bottom: 20px;
}
.badgeSize.ant-badge-status .ant-badge-status-dot{
  width: 15px;
  height: 15px;
  border-radius: 0%;
}
.discontinued-course-row {
  background-color: rgb(233 233 233);
  .ant-table-column-sort{
    background-color: rgb(233 233 233) !important;
  }
}
.formattedInputField{
  border:unset !important;
  border-bottom: 1px solid #000 !important;
  border-radius:0px !important;
  pointer-events:none !important;
}
.success_message{
  background-color: $success;
  color: $white;
  border-radius: 5px;
  padding: 12px;
  margin-bottom: 15px;
}
.icon-margin-adjust{
  margin-right: 5px;
}
.info-margin-adjust{
  margin-bottom: 10px;
}
.facultyName-style{
  background: aliceblue;
  padding: 5px;
  margin-left: 10px;
  border-radius: 5px;
  width: fit-content;
  font-weight: lighter;
}
label{
  font-weight: bold;
}
.select-container{
 width: 160px !important;
}
.checkbox-align{
  margin-top: 5px;
}
.tab-margin.ant-tabs-top >.ant-tabs-nav{
  margin-bottom: 0px;
}
.select-style{
  width: 100% !important;
}
.font-align{
  margin-right: 8px;
  margin-top: 6px;
}
 .select-dropdown{
  z-index: 1100;
}
.datePickerWidth{
  width:100%
}
.btnStyle{
  margin-left: 10px;
}
.view-student-style{
  display: contents;
 }
.table-number-align{
  text-align: end;
  display: block
}
.flex-center{
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancel-icon{
  color: $danger;
}
.alignSearch{
  margin-left: 20px;
}
.box-color{
  background-color: $light-grey;
  padding:10px;
  padding-top: 10px;
  border-radius: 10px;
}
.student-dashboard-welcome-container{
  background-color: $primary-pale;
  color:$white ;
  border-radius: 10px;
  padding: 20px;
  margin-left: 10px;
  margin-right: 50px;
  margin-top: 20px;
}
.welcome-text{
  display: flex;
  justify-content: center;
  align-items: center;
}
.dashboard-card-head{
  margin-left: 10px;
  margin-right: 50px;
  margin-top: 20px;
  margin-bottom: 10px;
}
.dashboard-card-head .ant-card-head {
  background-color: #3c8dbc;
  color: $white;
  text-align: center;
}
.welcome-text-body{
  margin: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
}
.adjust-margin{
  margin-bottom: 20px;
}
.cardColor .ant-card-head{
  background-color: $primary-pale ;
  color: $white;
  min-height: 27px;
}

.facultyMargin{
  margin-top: 40px;
}
.dynamicInput{
  margin-bottom: 20px;
}
.row-adjust{
  margin-bottom: -20px;
}
.collapse-color{
  background-color: #3c8dbc;
}
.nofication-align{
  margin-top: 10px;
}

.ant-modal:not(.transparent-modal){
  border-radius: 0;
  .ant-modal-content{
    padding: 0;
    .ant-modal-close{  .anticon svg {
      color: $white;
    }
  }
    .ant-modal-footer{
      // padding-bottom: 24px;
      padding: 10px;
    }
    .ant-modal-header{
      padding: 15px;
      background-color: $primary-pale;
    
      .ant-modal-title{
        color: $white;
        font-weight: 500 !important;
      }
    }
    .ant-modal-body{
      margin:1rem;

      // .ant-table-wrapper{
      //   margin-bottom: 1rem;
      //   padding-bottom: 1rem;
      // }
    }
  }
}

.email-details-btn {
  @include CommonBG($cyan, $dark-cyan);
  color: $white !important;

  &:hover {
    @include CommonBG($cyan, $dark-cyan);
  }
}

.ant-upload-picture-circle-wrapper{
  border-radius: 50%;
  .ant-upload-select{
    background-color: $light-grey !important;
  }
  &.avatar-upload-lg{
    .ant-upload-select {
        width: 8rem !important;
        height: 8rem !important;
      }
  }
}

.banner-radius {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 100px;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.h-100px{
  height: 100px;
}

.bg-primary-collapse {
  border: none;
  .ant-collapse-item {
    background-color: $primary-pale;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 1px 3px 5px #c6c6c6;
  }
}

.success-icon{
  color:$success;
}

.error-icon{
  color: $danger;
}

.partial-error{
  color: $yellow;
}

.email-container{
  position: relative;
}

.res-loader{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.131);
  z-index: 9999;
}

.search-size{
  width:150px;
  padding-left:20px;
}

.back-login-btn{
  color: $primary-pale;
  :hover{
    color: $orange;
  }
}

.input-alert{
  color:$danger;
}

.list-border-dark{
  .ant-list-item{
    border-color: $darker-grey !important;
}
}
.failedImportCard{
  border: none;
  border-radius: 0;
}
.collapse-custom {
  box-shadow: 0 2px 4px rgb(105 117 123 / 61%);
  .ant-collapse-header {
    background-color: $primary-pale;
    color: $white  !important;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    .ant-collapse-expand-icon .ant-collapse-arrow{
      font-size: large !important;
    }
  }
}

.ant-pagination{
  .ant-pagination-options .ant-select .ant-select-selector{
    display:none;
  }
  .ant-pagination-item{
    border:1px solid $white-gray !important;
    border-radius:0px;
  }
  .ant-pagination-item-active{
    color:$white;
    border-radius:0px;
    background-color: $primary-pale;
  }
} 
  
 .prevBtn,.nextBtn{
  border-radius:0px !important;
 }

.advisor-row{
  background-color:$light-blue;
  background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%);
  .ant-table-column-sort{
    background-color:$light-blue !important;
    background-image: linear-gradient(to bottom, #d9edf7 0, #b9def0 100%) !important;
  }
}
.ant-modal-confirm-btns{
  margin: 1rem;
}
.status-0 {
  background-color: $white;
}

.status-1 {
  background-color: #F7EED1;
}

.status-2 {
  background-color: #C8F3E0;
}

.status-3 {
  background-color: #EBC2BD;
}
.gray-title{
  .ant-card-head{
    background-color: #f5f5f5;
    background-image: linear-gradient(to bottom, #f5f5f5 0, #e8e8e8 100%);
  }
}
.current-year{
  .ant-card-head{
    background-color: #bfe1f1;
    background-image: linear-gradient(to bottom, #bfe1f1 0, #e8e8e8 100%);
  }
}
.columnbar {
  display: flex;
}
.heading6 {
  margin-left: 10px;
  margin-right: 20px;
}
.date-input{
  width:100%
}

.profile-main{
  justify-content: center;
  width:21rem;
  .profileCard {
  background-color: $primary-pale;
  justify-content: center;
  display: flex;
  border-radius: 0;
  text-align: center;
  border-color: $primary-pale;
  .profile-image{
    border-radius:50px;
    width: 40px;
  }
  .profile-name{
    color: $white;
  }
 
}
.profile-menu-footer{
  padding: 0.5rem;
}
}

.profile-popover .ant-popover-inner:not(.profile-menu-footer){
  padding: 0;
}

.switch-style{
  width: '120px';
  background-color: $primary-pale; 
}
.other-history-content{
  align-content: center;
}
.student-form-header{
  background-color: $dark-cyan;
  color: $white;
}
.status-select{
  .ant-select-selection-item{
    line-height: 0 !important;
  }
}
.transparent-modal{
  border-radius: 0;
  padding: 1rem;
  .ant-modal-content{
    padding: 0;
    .ant-modal-close{  .anticon svg {
      color: $white;
    }
  }
    .ant-modal-header{
      padding: 15px;
      background-color: $primary-pale;
    
      .ant-modal-title{
        color: $white;
      }
    }
    .ant-modal-body{
      margin:1rem;
    }
  }
}

.no-decoration-list {
  list-style: none; /* Remove bullets */
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}
.adjust-padding {
  padding: 0px 10px 30px 10px;
}
.form-padding{
  padding: 0px 20px 0px 20px;
}
.student-notes-css{
  font-weight: bold;
  padding-bottom: 5px;
  padding-top: 10px;
}
.action-btn1{
  width: 100%;
  margin: 10px 0px 10px 0px;
}
.img-size{
  height: 50%;
  width: 50%;
}
.sigCanvas{
  border: 1px solid $darker-grey;
}
.list-size {
  width: 250px;
}
.view-button {
  text-align: center;
  padding: 10px;
}
.close-icon {
  position: absolute;
  top: -2px;
  right: -2px;
  color: $grey;
}
.info-message {
  padding-top: 10px;
}
.checkboxMargin.ant-checkbox-wrapper span:not(.ant-checkbox , .ant-checkbox-inner){
  margin-top: 9px
}
.AshaMargin{
  margin-top: 30px;
}
.badge-style {
  .ant-scroll-number {
    background-color: $orange;
    box-shadow: none;
    border-radius: 3px;
  }
}
.partial-message {
  width: 170px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.partial-message:hover {
  width: auto;
  white-space: normal;
  overflow: hidden;
}
.mentor-request-style {
  padding: 10px 0px 10px 0px;
}
.signup-border-color {
  border-color: $primary;
  border-style: ridge;
  border-width: 1.5px;
  border-radius: 10px;
  padding: 9px;
  color: $primary;
}
.numbers-alignment {
  display: flex;
  justify-content: flex-end;
  padding-right: 20px;
}
.input-info{
  color:$dark-cyan;
}
.clearbtn-size {
  width: 230px;
}
.advisement-card .ant-card-head {
  background-color: $primary-pale;
  color: $white;
  padding: 10px;
}
.advisement-card .ant-card-body {
  height: 200px;
  overflow-y: auto;
  border-bottom: 1px solid $white-gray;
}
.flex-container {
  display: flex;
  align-items: center;
}
.notification-popover .ant-popover-inner{
  padding: 0;
}
.ant-input-outlined[disabled] { 
   color: $dark-grey;
}

.ant-select-outlined.ant-select-disabled:not(.ant-select-customize-input) .ant-select-selector{
  color: $dark-grey;
}

.ant-btn-primary.ant-btn-dangerous {
  background: $danger !important;
  color: $white !important;
  &:disabled{
    opacity: 0.5 !important;
  }
}

.gpa-close-tag{
  background-color: $sky-blue;
  border-radius: 50px;
  text-align: center;
}

.gpa-close-icon{
    background-color: red;
    color: #f4f4f4;
    border-radius: 50px;
    font-size: small;

}
.ant-switch {
  height: 30px !important;
  line-height: 29px;

  .ant-switch-handle{
    top: 3px;
    width: 23px;
    height: 23px;

    &:before{
      border-radius: 10px;
    }
  }

  .ant-switch-inner {
    .ant-switch-inner-checked,
    .ant-switch-inner-unchecked{
      font-size: 17px !important;
      padding: 0 10px 0 10px;
    }
    .ant-switch-inner-unchecked{
      margin-top: -28px;
    }
  }

  &.ant-switch-checked {
    background-color: $primary-pale;
    &:hover {
      background-color: $primary-dark !important;
    }
    .ant-switch-handle {
      inset-inline-start: calc(100% - 26px);
    }
  }

}

.disable-row{
  pointer-events: none;
  cursor: not-allowed;
}
.ant-menu-item-icon {
  width: 20px;
}
.ant-form-item-required{
  &::before{
    display: none !important;
  }
  &::after{
    display: inline-block;
    margin-inline-end: 4px !important;
    color: #ff4d4f;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: "*" !important;
    visibility: visible !important;
  }
}
.ant-table-wrapper .ant-table-thead >tr>th{
  font-weight: 500;
}
.upload-file-list {.ant-upload-list-item{
  height: 25px !important;
  background: #dfe3e7;
  border-radius: 8px;
  padding: 15px;}
}
.upload-file-list {.ant-upload-list-item .ant-upload-list-item-actions .ant-upload-list-item-action {
  opacity: 5 !important;}
}

.ant-select-dropdown .ant-select-item-group {
  color: $dark-grey;
  cursor: default;
  font-weight: 600;
  font-size: small;
}

.ant-picker .ant-picker-input >input[disabled] {
  color: $dark-grey;
}

.radioSwitch .ant-radio-button-wrapper >.ant-radio-button {
  background-color: $primary-pale;
  border-color:$primary-pale ;
}
.radioSwitch .ant-radio-button-wrapper:hover {
  position: relative;
  color: $primary-pale;
}
.radioSwitch .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
  background-color: $primary-pale !important;
  border-color: $primary-pale !important;
}
.ant-radio-button-wrapper {
  font-weight: 500;
}
.uneditable{
  pointer-events: none !important;
}
.change-password-btn{
  &:hover{
    background-color: $white-gray;
    border-color:$grey !important;
    color: $darker-grey !important;
  }
}
.table-middle{
  td{
    vertical-align: middle !important;
  }
}

ol, ul, menu{
  list-style-type: disc;
}

.eye-icon-span{
  background-color: $invisible !important;
  border: none !important;
  cursor: pointer;
  height: 38px;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 10; // Ensures the icon is above the input field
  display: flex;
  align-items: center;
  justify-content: center;
  .eye-icon{
    color:$grey;
    &:hover{
      color: $pale-grey !important;
    }
  }
}
.input-group {
  position: relative;
  .form-control {
    padding-right: 2.5rem; /* Adjust this value based on the size of the icon */
  }
  .input-group-text.eye-icon-span {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0.75rem; /* Adjust based on your design */
  }
}
.reset-password-field-errors{
  color: $danger; 
  margin: 0; 
  display: block;
  position: relative;
  bottom: 12px !important; 
}
.toSpan{
  min-width: 100px;
  max-width: 150px;
  transition: width 0.3s;
}

.profile-btn{
  background-color:$lime-green;
  color: $white;
}
.profile-popover .ant-popover-arrow::after{
  background-color:$primary-pale;
}
.read-only-mode{
  cursor: not-allowed;
  table,button {
    pointer-events: none;
  }
}
.expired-card .ant-card-body {
 padding:0 !important;
}
.reset-img {
  background-image: url('./Assets/login.jpg');
  background-size: cover;
  padding: 1rem 2.5rem;
  border-radius:8px 0 0px 8px;
}
.excelInput {
  .ant-upload-btn{
    height: calc(100vh - 440px);
  }
  .ant-upload-list-item {
    height: 50px !important;
  }
}
.readable-checkbox{
  .ant-checkbox-wrapper-checked.ant-checkbox-wrapper-disabled{
    .ant-checkbox-inner{
      background: $primary-pale !important;
      border-color: $primary-dark !important;
      &::after{
        border-color: $white !important;
      }
    }
  }
}
.ant-checkbox:not(.ant-checkbox-disabled) {
  .ant-checkbox-inner{
    border-color: $dark-grey;
  }
}
.upload-list-mb-0{
  .ant-upload-list-item{
    margin-top: 0 !important;
  }
}
.ant-card-head-wrapper{
  flex-wrap: wrap;
  gap: 3px;
}
.img-sm{
  img{
    width: 35%;
    margin: auto;
  }
}
.img-lg{
  img{
    min-width: 30%;
    max-width: 60%;
    margin: auto;
  }
}
.noti-mb-0{
  p{
    margin-bottom: 0 !important;
  }
}
.ant-menu-inline-collapsed{
  width: 100%;
}
.radio-top{
  .ant-radio{
    top: 3px;
  }
}
.mobile-menu{
  .ant-drawer-header{
    background-color: #001529;
  }
  .ant-drawer-body{
    padding: 0;
    background-color: #001529;
  }
}
.hide-handle{
  .ant-input-number-handler-wrap{
    display: none;
  }
}
@media (max-width: 575.98px) {
  .student-staff-tabs.nav-underline {
    .nav-item {
      width: 46%;
    }
  }
  .email-details-btn {
    width:100%;
  }

  .card-tabs.nav-tabs {
    justify-content: center;
    flex-direction: column;
    gap: 0.1rem;

    .nav-link {
      width: 100%;
      text-align: center;

      &.active {
        border-bottom-color: $primary;
        border-bottom-left-radius: var(--bs-nav-tabs-border-radius);
        border-bottom-right-radius: var(--bs-nav-tabs-border-radius);
      }
    }
    
    .header-text{
      padding: 1rem;
    }
  }

  .records-table {
    .ant-pagination {
      justify-content: center !important;
    }

    .ant-pagination-total-text {
      text-align-last: center !important;
    }
  }
  .upload-img{
    position: absolute;
    
  }
  
  .search-inp{
    width: 100%;
    
  }
}

@media (max-width: 767.98px) {
  .upload-container {
    flex-direction: column;

    .ant-upload.ant-upload-drag {
      width: 100%;
      background-color: $light-blue;
      height: auto;
    }

    .ant-upload-list.ant-upload-list-picture {
      width: 100%;
    }
  }
  
  .faculty-inp{
    width: 90%;
  }

  .register-card {
    margin-top: 0rem;
  }
}
.failed-import-header{
  background-color:$dark-cyan;
  color:$white ;
}
@media (max-width: 991.98px) {
  .current-page-link {
    background: $white-gray;
  }
	  .excelInput {
	  	.ant-upload-btn {
	  		height: unset;
	  	}
	  }
}
@media (max-width: 1024px) {
  .mainFilters{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .filters{
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media (min-width: 1024px) {
  .mainFilters{
    flex-wrap: nowrap;
    justify-content:space-between;
  }
  .filters{
    flex-wrap: nowrap;
  }
}