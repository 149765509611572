$primary: #337AB7;
$primary-pale: #3c8dbc;
$primary-hover: #357ca5;
$primary-dark:#286090;
$cyan: #00c0ef;
$dark-cyan: #00acd6;
$pale-blue: #86b7fe;
$light-blue:#cff3fc;
$disabled:#7192b0;
$sky-blue:#bfe9ff;
$body-color:#f0f8ff;
$btn-color:#337ab8;

$success: #00a65a;
$danger: #dd4b39;
$yellow: #e3aa00;

$white: #ffffff;

$grey: #6c757d;
$white-gray: #ddd;
$light-grey: #ecf0f5;
$lighter-grey:#333;
$dark-grey: #444;
$pale-grey: #222d32;
$darker-grey: #1e282c;

$invisible: transparent;

$orange:#FC8213;
$green:#1ae789;
$lime-green:#5cb85c;
$darker-lime-green:rgb(22 163 74);