@mixin CommonBG ($bg-color, $border-color) {
  background: $bg-color !important;
  border-color: $border-color !important;
}

@mixin Sidemenu($border-color) {
  border-left: 3px solid;
  border-color: $border-color;
  border-radius: 0;
  width: 100%;
  margin-right: 0;
  margin-left: 0;
}

@mixin RecordTable() {
  background-color: $primary-pale !important;
  color: $white !important;
}

@mixin widget{
  position: relative;
  margin: 0;
}